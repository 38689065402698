/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "/node_modules/ag-grid-community/styles/ag-grid.css";
@import "/node_modules/ag-grid-community/styles/ag-theme-material.css";

:root {
  --color-background: #f5f5f5;
  --color-white: #ffffff;
  --color-text: #404040;
  --color-grey1: #8c8c8c;
  --color-grey2: #8a92a6;
  --color-blue1: #001f82;
  --color-blue2: #005eef;
  --color-card1: #ffc000;
  --color-card2: #00d7c4;
  --color-card3: #cf98d9;
  --color-card4: #ff7c7d;
  --color-card5: #08b1ba;
  --form-title-color: #808080;
  --vertical-ruler-color: #f2f2f2;
  --modal-text-color: #282828;
  --text-normal:400;
  --text-bold:700;
  --text-area:14px;
  --font-family: Arial, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-family);
}

.action-btn {
  padding: 0.8rem 2.4rem;
  border-radius: 0.5rem;
  color: var(--color-white);
  background-color: var(--color-blue1);
  border: 1px solid var(--color-blue1);
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
}

.action-outline-btn {
  padding: 0.8rem 2.4rem;
  border-radius: 0.5rem;
  color: var(--color-blue1);
  border: 1px solid var(--color-blue1);
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
}

.disable-btn {
  opacity: 0.7;
  cursor: not-allowed;
}